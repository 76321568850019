import { ANALYTICS } from './analytics';
// coverages
const proteccionNegocio = {
  url: 'proteccion-negocio',
  title: 'Protección Negocio',
  image: 'images/logo-pn2.svg',
  subtitle: 'Negocio',
  color: '#ffb400',
  description: {
    image: 'images/logo-pn2.svg',
    bgHeader: '#ffb400',
    title: '¿Qué es Protección Negocio?',
    content: `Microseguro que cubre la mercadería, mobiliario, equipo de oficina y máquinas que sean de propiedad del cliente y se encuentren dentro del negocio cuando se dañe por un incendio, desastres naturales, daños por agua, huelga, entre otros.<br/><br>
        En caso de siniestro el microseguro cubrirá el monto total de la pérdida del cliente (máximo el monto total del crédito). Se pagará primero la deuda pendiente con Mibanco para que pueda continuar su negocio y de haber una diferencia se le entregará al cliente.<br/><br/>
        La vigencia del microseguro inicia desde la fecha de desembolso del crédito y estará vigente durante el plazo original del crédito.<br/><br/>
        Monto máximo asegurable: S/150,000<br/><br/>
        `,
    examples: [
      {
        title: 'Cómo funciona',
        subtitle: 'Cuando la pérdida es mayor que el prestamo.',
        analytics: 'description/example-1',
        thumbnail: 'images/examples/pn/1/pn-thumbnail-1.svg',
        secondarySlider: true,
        content: [
          {
            title:
              'Pedro sacó un crédito de S/ 10 000. Como compró su microseguro Protección Negocio, su negocio está cubierto hasta S/ 10 000.',
            thumbnail: 'images/examples/pn/1/pn-comousar-1-1.svg',
            bar1: {
              description1: '',
              description2: '',
              bar: [
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
              ],
            },
            bar2: {
              description1: '',
              description2: '',
              bar: [
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title: 'Ya pagó S/ 7 000 por el crédito y le falta pagar S/ 3 000.',
            thumbnail: 'images/examples/pn/1/pn-comousar-1-2.svg',
            bar1: {
              description1: 'Pagados S/ 7,000',
              description2: 'Falta pagar S/ 3 000',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green-active',
                'green-active',
                'green-active',
              ],
            },
            bar2: {
              description1: '',
              description2: '',
              bar: [
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title:
              'Pedro sufrió un incendio en su local y perdió S/15 000 en mercadería, que es un monto mayor a su cobertura.',
            thumbnail: 'images/examples/pn/1/pn-comousar-1-3.svg',
            bar1: {
              description1: 'Pagados S/ 7,000',
              description2: 'Falta pagar S/ 3 000',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green-active',
                'green-active',
                'green-active',
              ],
            },
            bar2: {
              description1: '',
              description2: '',
              bar: [
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title:
              'Para que Pedro pueda continuar con su negocio sin preocuparse por el crédito, su microseguro Protección Negocio pagó primero su deuda de S/ 3 000.',
            thumbnail: 'images/examples/pn/1/pn-comousar-1-4.svg',
            bar1: {
              description1: '',
              description2: ' Deuda pagada por el microseguro',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
            bar2: {
              description1: 'Quédan S/ 7 000 en el microseguro',
              description2: '',
              bar: [
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'outline',
                'outline',
                'outline',
              ],
            },
          },
          {
            title:
              'Como su negocio estaba cubierto hasta S/ 10 000, le entrega los S/ 7 000 de diferencia para ayudarlo a recuperar su negocio.',
            thumbnail: 'images/examples/pn/1/pn-comousar-1-5.svg',
            bar1: {
              description2: ' Deuda pagada por el microseguro',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
            bar2: {
              description1: '',
              description2: ' Microseguro totalmente utilizado',
              bar: [
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
              ],
            },
          },
        ],
      },
      {
        title: 'Cómo funciona',
        subtitle: 'Cuando la perdida es menor que el prestamo.',
        analytics: 'description/example-2',
        thumbnail: 'images/examples/pn/2/pn-thumbnail-2.svg',
        secondarySlider: true,
        content: [
          {
            title:
              'Juana sacó un crédito de S/ 10 000. Como compró su microseguro Protección Negocio, su negocio está cubierto hasta S/ 10 000.',
            thumbnail: 'images/examples/pn/2/pn-comousar-2-1.svg',
            bar1: {
              description1: '',
              description2: '',
              bar: [
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
                'green-active',
              ],
            },
            bar2: {
              description1: '',
              description2: '',
              bar: [
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title: 'Ya pagó S/ 7 000 por el crédito y le falta pagar S/ 3 000.',
            thumbnail: 'images/examples/pn/2/pn-comousar-2-2.svg',
            bar1: {
              description1: 'Pagados S/ 7,000',
              description2: 'Falta pagar S/ 3 000',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green-active',
                'green-active',
                'green-active',
              ],
            },
            bar2: {
              description1: '',
              description2: '',
              bar: [
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title:
              'Juana sufrió un incendio en su local y perdió S/6,000 en mercadería, que es un monto menor que el monto del crédito.',
            thumbnail: 'images/examples/pn/2/pn-comousar-2-3.svg',
            bar1: {
              description1: 'Pagados S/ 7,000',
              description2: 'Falta pagar S/ 3 000',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green-active',
                'green-active',
                'green-active',
              ],
            },
            bar2: {
              description1: 'Cobertura de S/ 6 000',
              description2: 'Disponible de S/ 4 000',
              bar: [
                'orange',
                'orange',
                'orange',
                'orange',
                'orange',
                'orange',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title:
              'Para que Juana pueda continuar con su negocio sin preocuparse por el crédito, su microseguro Protección Negocio pagó primero su deuda de S/ 3 000.',
            thumbnail: 'images/examples/pn/2/pn-comousar-2-4.svg',
            bar1: {
              description1: ' Deuda pagada por el microseguro',
              description2: '',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'orange',
                'orange',
                'orange',
              ],
            },
            bar2: {
              description1: 'S/ 3 000 usados   Quédan S/ 3 000',
              description2: 'Disponible S/ 4 000',
              bar: [
                'outline',
                'outline',
                'outline',
                'orange',
                'orange',
                'orange',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
          {
            title:
              'Además, le entrega S/ 3 000 para ayudarla a recuperar su negocio. El saldo restante del microseguro servirá como respaldo si sucede otro siniestro durante el plazo original del crédito.',
            thumbnail: 'images/examples/pn/2/pn-comousar-2-5.svg',
            bar1: {
              description1: 'Deuda pagada por el microseguro',
              description2: '',
              bar: [
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'green',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
            bar2: {
              description1: '',
              description2: ' Disponible S/ 4 000',
              bar: [
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'outline',
                'orange-active',
                'orange-active',
                'orange-active',
                'orange-active',
              ],
            },
          },
        ],
      },
    ],
  },
  coverages: {
    cards: [
      {
        title: '¿Cuándo le cubre?',
        items: [
          {
            title: 'Incendios y daños por agua',
            thumbnail: 'images/ilustracion-accidentes.svg',
            description: 'Esta cobertura protege en los siguientes casos:',
            details: [
              { icon: 'torito-icon-accident', name: 'Incendios' },
              { icon: 'torito-icon-accident', name: 'Explosiones' },
              { icon: 'torito-icon-accident', name: 'Daño por humo' },
              { icon: 'torito-icon-accident', name: 'Daños por agua' },
            ],
          },
          {
            title: 'Desastres naturales',
            thumbnail: 'images/ilustracion-desastres.svg',
            description: 'Esta cobertura protege en los siguientes casos:',
            details: [
              { icon: 'torito-icon-accident', name: 'Huaycos' },
              { icon: 'torito-icon-accident', name: 'Lluvias e inundación' },
              { icon: 'torito-icon-accident', name: 'Terremoto' },
              { icon: 'torito-icon-accident', name: 'Maremoto' },
            ],
          },
          {
            title: 'Actos causados por otros',
            thumbnail: 'images/ilustracion-actos-por-otros.svg',
            description: 'Esta cobertura protege en los siguientes casos:',
            details: [
              { icon: 'torito-icon-accident', name: 'Huelga' },
              { icon: 'torito-icon-accident', name: 'Vandalismo' },
              { icon: 'torito-icon-accident', name: 'Terrorismo' },
              { icon: 'torito-icon-accident', name: 'Daño malicioso' },
            ],
          },
          {
            title: 'Robos y asaltos',
            thumbnail: 'images/ilustracion-robo.svg',
            alert: true,
            description: `Esta cobertura está disponible cuando el monto del crédito es mayor a S/ 24 000.<br/><br/>
                        Aplica cuando se apoderan de los bienes asegurados de propiedad del asegurado sin su consentimiento, utilizando violencia o sufriendo una amenaza. Debe haber evidencia del robo.`,
          },
        ],
      },
      {
        title: '¿Qué le cubre?',
        items: [
          {
            title: 'Mercaderias y existencias',
            thumbnail: 'images/ilustracion-mercaderia.svg',
            description: '',
            details: [
              { icon: 'torito-icon-objects', name: 'Insumos para producción' },
              { icon: 'torito-icon-objects', name: 'Artículos para venta' },
            ],
          },
          {
            title: 'Equipos de oficina',
            thumbnail: 'images/ilustracion-computadora.svg',
            description: '',
            details: [
              { icon: 'torito-icon-objects', name: 'Computadoras' },
              { icon: 'torito-icon-objects', name: 'Televisores' },
              { icon: 'torito-icon-objects', name: 'Impresoras' },
              { icon: 'torito-icon-objects', name: 'Laptop y tablets' },
              { icon: 'torito-icon-objects', name: 'Escaners' },
              { icon: 'torito-icon-objects', name: 'Teléfonos' },
            ],
          },
          {
            title: 'Muebles',
            thumbnail: 'images/ilustracion-muebles.svg',
            description: '',
            details: [
              { icon: 'torito-icon-objects', name: 'Sillones' },
              {
                icon: 'torito-icon-objects',
                name: 'Estanterías no empotradas',
              },
              { icon: 'torito-icon-objects', name: 'Vitrinas' },
              { icon: 'torito-icon-objects', name: 'Sillas' },
              { icon: 'torito-icon-objects', name: 'Armarios no empotrados' },
            ],
          },
          {
            title: 'Útiles',
            thumbnail: 'images/ilustracion-utiles.svg',
            description: '',
            details: [
              { icon: 'torito-icon-objects', name: 'Archivadores' },
              {
                icon: 'torito-icon-objects',
                name: 'Lapiceros, marcadores, etc.',
              },
              { icon: 'torito-icon-objects', name: 'Papelería' },
            ],
          },
          {
            title: 'Herramientas e instrumentos',
            thumbnail: 'images/ilustracion-herramientas.svg',
            description: 'Toda herramienta relacionada al giro del negocio.',
          },
          {
            title: 'Maquinarias',
            thumbnail: 'images/ilustracion-maquinarias.svg',
            description:
              'Toda máquina relacionada al giro del negocio, excluyendo vehiculos.',
          },
        ],
      },
    ],
    conditions: `
        Los bienes a cubrir deben ser propiedad del asegurado.<br/><br/>
        El asegurado puede declarar hasta 4 locales indicando sus respectivas direcciones del negocio y % de suma asegurada para cada uno. En caso de siniestros, los bienes afectados serán lo ubicados dentro de los locales declarados.<br/><br/>
        Los bienes a cubrir deben guardar relación con el giro del negocio de los locales declarados.
        `,
  },
  exclutions: {
    cards: [
      {
        title: '¿Cuándo no le cubre?',
        items: [
          {
            id: 'pn_excl_0',
            title: 'Pérdidas o daños causados por el cliente',
            thumbnail: 'images/ilustracion-perdidas.svg',
            description:
              'Daños intencionales - no accidentales - que realiza el cliente a su local.',
          },
          {
            id: 'pn_excl_1',
            title: 'Daños por guerra',
            thumbnail: 'images/ilustracion-guerra.svg',
          },
          {
            id: 'pn_excl_2',
            title: 'Lucro cesante a causa de siniestro',
            thumbnail: 'images/ilustracion-lucrocesante.svg',
            description:
              'Ganancia que deja de percibir el negocio debido a un siniestro.',
          },
          {
            id: 'pn_excl_3',
            title: 'Incendio de plantaciones',
            thumbnail: 'images/ilustracion-incendioverde.svg',
          },
          {
            id: 'pn_excl_4',
            title: 'Robos sin evidencia',
            thumbnail: 'images/ilustracion-hurto.svg',
            description:
              'Robos realizados sin que el cliente se dé cuenta o sin que exista una evidencia objetiva sobre el robo.',
          },
          {
            id: 'pn_excl_5',
            title: 'Deshonestidad de los empleados',
            thumbnail: 'images/ilustracion-deshonestidad.svg',
          },
          {
            id: 'pn_excl_6',
            title: 'Deterioro',
            thumbnail: 'images/ilustracion-deterioro.svg',
          },
        ],
      },
      {
        title: '¿Qué no le cubre?',
        items: [
          {
            title: 'Animales',
            thumbnail: 'images/ilustracion-animales.svg',
          },
          {
            title: 'Explosivos',
            thumbnail: 'images/ilustracion-explosivos.svg',
          },
          {
            title: 'Vehículos',
            thumbnail: 'images/ilustracion-vehiculos.svg',
          },
          {
            title: 'Dinero en efectivo',
            thumbnail: 'images/ilustracion-dinero.svg',
          },
          {
            title: 'Joyas y objetos de valor',
            thumbnail: 'images/ilustracion-joyas.svg',
          },
          {
            title: 'Bienes que no son del asegurado',
            thumbnail: 'images/ilustracion-cosas.svg',
          },
          {
            title: 'Gastos por restitución de documentos',
            thumbnail: 'images/ilustracion-documentos.svg',
          },
          {
            title: 'Daños de Infraestructura',
            thumbnail: 'images/ilustracion-da-osinfraestructura.svg',
          },
        ],
      },
      {
        title: 'Principales rubros no cubiertos',
        items: [
          {
            title: 'Colchones y espumas',
            thumbnail: 'images/ilustracion-colchones.svg',
          },
          {
            title: 'Cauchos o llantas',
            thumbnail: 'images/ilustracion-cauchos.svg',
          },
          {
            title: 'Pirotécnicos y explosivos',
            thumbnail: 'images/ilustracion-pirotecnicos.svg',
          },
          {
            title: 'Casinos y discotecas',
            thumbnail: 'images/ilustracion-casinos.svg',
          },
          {
            title: 'Crianza animales',
            thumbnail: 'images/ilustracion-crianza.svg',
          },
          {
            title: 'Plantaciones y cultivos',
            thumbnail: 'images/ilustracion-crianza.svg',
          },
        ],
      },
    ],
  },
  howtouse: {
    title: 'Después de un accidente…',
    items: [
      {
        title: '¿Qué hacer?',
        url: '/home',
        analytics: ANALYTICS.MODAL_HOWTOUSE_WHATTODO,
        items: [
          {
            title: `
                En caso de siniestro el cliente deberá ir a Mibanco, pedir el Reporte de
                Siniestros y adjuntar la documentación correspondiente.
              `,
            thumbnail: 'images/ilustracion-quehacer-1@2x.png',
          },
          {
            title: `Mibanco enviará el reporte y la documentación a Pacífico Seguros.`,
            thumbnail: 'images/ilustracion-quehacer-2@2x.png',
          },
          {
            title: `
                Pacífico Seguros enviará un perito al cliente, quien se encargará de estimar el
                detalle de pérdidas y la cobertura.
              `,
            thumbnail: 'images/ilustracion-quehacer-3@2x.png',
          },
          {
            title:
              'Pacífico tiene hasta 30 días calendarios para dar la respuesta sobre el pago del seguro.',
            thumbnail: 'images/ilustracion-quehacer-4@2x.png',
          },
        ],
      },
      {
        title: '¿Qué documentos necesita?',
        url: '/home',
        analytics: ANALYTICS.MODAL_HOWTOUSE_WHATDOCUMENTS,
        content: [
          {
            text:
              'Para todos los tipos de siniestros, se requieren los siguientes documentos:',
            items: [
              'Proformas del gasto de reparación  o reemplazo de los bienes afectados.',
              'Lista detallada de la pérdida y prueba de pre-existencia.',
            ],
          },
          {
            text:
              'Adicionalmente, en caso de incendios o daños causados por rayos:',
            items: ['Informe de ocurrencia de los bomberos.'],
          },
          {
            text:
              'Adicionalmente, en caso de robo y siniestros causados por otros:',
            items: ['Denuncia policial.'],
          },
        ],
      },
    ],
  },
};

const proteccionFamilia = {
  url: 'familia-protegida',
  title: 'Familia Protegida',
  image: 'images/logo_familia_protegida.svg',
  subtitle: 'Accidentes personales',
  color: '#3FB4E5',
  description: {
    image: 'images/logo_familia_protegida.svg',
    bgHeader: '#3FB4E5',
    title: '¿Qué es Familia Protegida?',
    content: `
        Es un microseguro que, por un costo fijo mensual de S/4.00, protege al cliente en casos de muerte accidental e invalidez total y permanente.
        <br/><br/>
        Por S/1.50 adicional, el cliente puede proteger a su cónyuge con las mismas coberturas.
        <br/><br/>
        En caso de siniestro el seguro cubrirá diferentes montos al beneficiario dependiendo de lo que pase:
        <div class="pre">
        <table>
            <thead>
                <tr>
                <th width="65%">Plan Individual S/ 4.00</th>
                <th class="right">Titular</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Muerte accidental</td>
                    <td class="right">S/ 15,000</td>
                </tr>
                <tr>
                    <td>Invalidez total permanente</td>
                    <td class="right">S/ 5,000</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="pre">
        <table>
            <thead>
                <tr>
                <th width="65%">Plan Familiar +S/ 1.50</th>
                <th class="right">Conyuge</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Muerte accidental</td>
                    <td class="right">S/ 15,000</td>
                </tr>
                <tr>
                    <td>Invalidez total permanente</td>
                    <td class="right">S/ 5,000</td>
                </tr>
            </tbody>
        </table>
        </div>
        Además, en caso el titular y su cónyuge/conviviente fallezcan de manera accidental en el mismo evento, se le entregará al beneficiario un monto adicional.
        <div class="pre">
        <table>
            <thead>
                <tr>
                <th width="65%">Cobertura</th>
                <th class="right">Indemnización</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Desamparo súbito familiar (Muerte conjunta del titular y su cónyuge)</td>
                    <td class="right">S/ 15,000 adicionales</td>
                </tr>
            </tbody>
        </table>
        </div>
        Se recomienda siempre especificar el o los beneficiarios, ya que de no hacerlo, el proceso de indemnización se prolonga por requerir certificados.
        <br/><br/>
        La vigencia del microseguro inicia desde la fecha de desembolso del préstamo y estará vigente durante el plazo original del préstamo.
        `,
    examples: [
      {
        title: 'Cómo funciona',
        subtitle: 'Muerte accidental',
        analytics: 'description/example-1',
        thumbnail: 'images/examples/fp/1/fp-thumbnail-1.svg',
        content: [
          {
            title:
              'María decidió añadir a su crédito el microseguro Familia Protegida por S/ 3 mensuales, que estará vigente hasta que termine el plazo original de su préstamo.',
            thumbnail: 'images/examples/fp/1/fp-comofunciona-1-1.svg',
          },
          {
            title:
              'Lamentablemente María fallece en un accidente de tránsito. Su cónyuge, el beneficiario, se acerca a Mibanco para solicitar la indemnización.',
            thumbnail: 'images/examples/fp/1/fp-comofunciona-1-2.svg',
          },
          {
            title:
              'Mibanco le envía el caso a Pacífico que, tras validar el caso, activa la cobertura de muerte accidental y en un promedio de 20 días, se entregan los 15,000 soles correspondientes a través de Mibanco al cónyuge de María.',
            thumbnail: 'images/examples/fp/1/fp-comofunciona-1-3.svg',
          },
        ],
      },
      {
        title: 'Cómo funciona',
        subtitle: 'Desamparo súbito',
        analytics: 'description/example-2',
        thumbnail: 'images/examples/fp/2/fp-thumbnail-2.svg',
        content: [
          {
            title:
              'Pedro sacó un crédito con Mibanco y decidió añadir el microseguro Familia protegida para él y su esposa, por S/4.50 mensuales. Este microseguro estará vigente hasta que termine el plazo original del préstamo.',
            thumbnail: 'images/examples/fp/2/fp-comofunciona-2-1.svg',
          },
          {
            title:
              'Lamentablemente Pedro y su esposa fallecen en un mismo accidente durante el plazo de la cobertura. Ellos tienen un hijo que es menor de edad, por lo que un familiar responsable se acerca a Mibanco para realizar las gestiones respectivas del microseguro.',
            thumbnail: 'images/examples/fp/2/fp-comofunciona-2-2.svg',
          },
          {
            title:
              'Mibanco le envía el caso a Pacífico que, tras validar el caso, activa la cobertura de desamparo súbito familiar y muerte accidental, entregando la indemnización al familiar responsable en aproximadamente 20 días.',
            thumbnail: 'images/examples/fp/2/fp-comofunciona-2-3.svg',
          },
          {
            title:
              'Mibanco otorga al familiar responsable: S/15,000 por el fallecimiento de Pedro; otros S/15,000 por el fallecimiento de la cónyuge de Pedro; y finalmente S/15,000 adicionales por ser un caso de desamparo súbito familiar.  Este dinero irá a una cuenta a nombre del hijo, que será accesible cuando sea mayor de edad.',
            thumbnail: 'images/examples/fp/2/fp-comofunciona-2-4.svg',
          },
        ],
      },
      {
        title: 'Cómo funciona',
        subtitle: 'Invalidez total',
        analytics: 'description/example-3',
        thumbnail: 'images/examples/fp/3/fp-thumbnail-3.svg',
        content: [
          {
            title:
              'Juan sacó un crédito con Mibanco y decidió añadir el microseguro Familia Protegida para él, por S/3.00 mensuales.',
            thumbnail: 'images/examples/fp/3/fp-comofunciona-3-1.svg',
          },
          {
            title:
              'Una vez adquirido el microseguro, Juan sufre un accidente en el que pierde la función de ambas piernas. Por lo que es certificado por un médico por invalidez total y permanente',
            thumbnail: 'images/examples/fp/3/fp-comofunciona-3-2.svg',
          },
          {
            title:
              'Ya que tiene el microseguro, gestiona la indemnización en Mibanco. Después de llenar la documentación, el caso se envía a Pacífico para su evaluación. ',
            thumbnail: 'images/examples/fp/3/fp-comofunciona-3-3.svg',
          },
          {
            title:
              'Después de un promedio de 20 días, se entregan los 5,000 soles de la cobertura de invalidez a través de Mibanco',
            thumbnail: 'images/examples/fp/3/fp-comofunciona-3-4.svg',
          },
        ],
      },
    ],
  },
  coverages: {
    cards: [
      {
        title: '¿Qué le cubre?',
        isLarge: true,
        items: [
          {
            title: 'Muerte accidental',
            thumbnail:
              'images/insurances/family/coverage/ilustracion-muerte.svg',
            description:
              'Muerte como consecuencia de un accidente. Solo si esta sucede dentro de los primeros 90 días después de ocurrido el accidente',
            details: [],
          },
          {
            title: 'Invalidez total y permanente',
            thumbnail:
              'images/insurances/family/coverage/ilustracion-invalidez.svg',
            description:
              'Cubre la invalidez total y permanente debidamente acreditada con los siguientes diagnósticos:',
            details: [
              {
                icon: 'torito-icon-mission',
                name:
                  'Ausencia de función cerebral y/o inactividad total del cerebro.',
              },
              {
                icon: 'torito-icon-mission',
                name: 'Pérdida total de visión de ambos ojos.',
              },
              {
                icon: 'torito-icon-mission',
                name: 'Pérdida completa de ambos brazos o ambas manos.',
              },
              {
                icon: 'torito-icon-mission',
                name:
                  'Pérdida completa de un brazo y una pierna o una mano y una pierna.',
              },
              {
                icon: 'torito-icon-mission',
                name:
                  'Pérdida completa de una mano y de un pie o de un brazo y un pie.',
              },
            ],
          },
          {
            title: 'Desamparo súbito familiar',
            thumbnail:
              'images/insurances/family/coverage/ilustracion-desamparo.svg',

            description:
              'Fallecimiento en un mismo accidente del asegurado y de su cónyuge o conviviente declarado/a en la póliza.',
            details: [],
          },
        ],
      },
    ],
  },
  exclutions: {
    cards: [
      {
        title: '¿Cuándo no le cubre?',
        items: [
          {
            title: 'Conflictos civiles/políticos',
            thumbnail: 'images/ilustracion-guerra.svg',
            description: 'Guerras, rebeliones, revolucón o golpe de estado',
          },
          {
            title: 'Radiación nuclear',
            thumbnail:
              'images/insurances/family/exclutions/ilustracion-radiacion.svg',
            description: 'Muerte causada por exposición a radiación',
          },
          {
            title: 'Manifestaciones',
            thumbnail:
              'images/insurances/family/exclutions/ilustracion-manifestaciones.svg',
            description:
              'Muerte causada por participación en huelgas, motín y conmoción civil.',
          },
          {
            title: 'Participación en actos delictivos',
            thumbnail:
              'images/insurances/family/exclutions/ilustracion-actos-por-otros.svg',
            description:
              'Muerte causada por actos violatorios de leyes o terrorismo.',
          },
          {
            title: 'Suicidio o lesiones hechas a uno mismo',
            thumbnail:
              'images/insurances/family/exclutions/ilustracion-exclusion-muerte.svg',
            description:
              'Suicidios o lesiones hechas a uno mismo dentro de los primeros 2 años de cobertura. De suceder después de 2 años de seguir coberturado, el cliente si estaría coberturado.',
          },
          {
            title: 'Muerte intencional al contratante',
            thumbnail:
              'images/insurances/family/exclutions/ilustracion-muerte-intencional.svg',
            description:
              'Muerte causada por los beneficiarios para poder cobrar la indemnización',
          },
          {
            title: 'Accidentes bajo efectos de alcohol y/o drogas',
            thumbnail:
              'images/insurances/family/exclutions/ilustracion-alcohol.svg',
            description: '',
          },
        ],
      },
    ],
  },
  howtouse: {
    title: 'Qué hacer en caso de…',
    items: [
      {
        title: 'Muerte accidental o desamparo',
        url: '/home',
        analytics: 'how-to-use/muerte-accidental',
        items: [
          {
            title:
              'El beneficiario debe acercarse a Mibanco a notificar la muerte del/los familiar/es.',
            thumbnail: 'images/htu/fp/1/fp-caso-1-muerte-1.svg',
          },
          {
            title:
              'El asesor le entregará y explicara el Registro de Indemnización de Seguros (RIS). En ese documento se marca la cobertura que se desea activar y se le indica que documentos adicionales debe presentar.',
            thumbnail: 'images/htu/fp/1/fp-caso-1-muerte-2.svg',
          },
          {
            title:
              'Toda la documentación entregada a Mibanco es enviada a Pacifico para su evaluación y después de ser validado se le envía el dinero a Mibanco para que sea entregado a disposición del beneficiario.',
            thumbnail: 'images/htu/fp/1/fp-caso-1-muerte-3.svg',
          },
        ],
      },
      {
        title: 'Beneficiario menor de edad',
        url: '/home',
        analytics: 'how-to-use/beneficiario-menor-edad',
        items: [
          {
            title:
              'Si el beneficiario es menor de edad, no puede hacerse cargo de las gestiones del seguro. Para este caso, necesita de un apoderado que lo haga por él/ella.',
            thumbnail: 'images/htu/fp/2/fp-caso-2-menor-1.svg',
          },
          {
            title:
              'El apoderado deberá presentar una copia de su DNI más los documentos del menor: la partida de nacimiento (original o copia) o la copia de su DNI.',
            thumbnail: 'images/htu/fp/2/fp-caso-2-menor-2.svg',
          },
          {
            title:
              'El asesor le entregará y explicará el Registro de Indemnización de Seguros (RIS) al apoderado. En ese documento se marca la cobertura que se desea activar y se le indica que documentos adicionales debe presentar.',
            thumbnail: 'images/htu/fp/2/fp-caso-2-menor-3.svg',
          },
          {
            title:
              'Una vez evaluado y validado el caso, Pacífico genera una cuenta a nombre del menor en el BCP, donde se depositará el dinero.',
            thumbnail: 'images/htu/fp/2/fp-caso-2-menor-4.svg',
          },
          {
            title:
              'El dinero de la cuenta será accesible una vez que el menor cumpla 18 años. Para solicitar el acceso, deberá presentar su DNI en el BCP.',
            thumbnail: 'images/htu/fp/2/fp-caso-2-menor-5.svg',
          },
          {
            title:
              'De querer tener acceso al dinero antes de que el menor cumpla 18 años, se podría asignar como beneficiario a un apoderado.',
            thumbnail: 'images/htu/fp/2/fp-caso-2-menor-6.svg',
          },
        ],
      },
      {
        title: 'Invalidez',
        url: '/home',
        analytics: 'how-to-use/invalidez',
        items: [
          {
            title:
              'El titular debe acercarse a Mibanco a notificar su estado de invalidez y pedir su indemnización.',
            thumbnail: 'images/htu/fp/3/fp-caso-3-invalidez-1.svg',
          },
          {
            title:
              'El asesor le entregará y explicará el RIS. En ese documento se marca la cobertura que se desea activar y se le indica que documentos adicionales debe presentar.',
            thumbnail: 'images/htu/fp/3/fp-caso-3-invalidez-2.svg',
          },
          {
            title:
              'Toda la documentación entregada a Mibanco es enviada a Pacifico para su evaluación y después de ser validado se le envía el dinero a Mibanco para que sea entregado a disposición del beneficiario.',
            thumbnail: 'images/htu/fp/3/fp-caso-3-invalidez-3.svg',
          },
        ],
      },
      {
        title: 'Documentos necesarios',
        url: '/home',
        analytics: 'how-to-use/documents',
        content: [
          {
            text: 'Para muerte accidental y desamparo súbito familiar:',
            items: [
              'Registro de Indemnización de Seguros (RIS) - Entregado por el banco',
              'Copia de DNI del beneficiario',
              'Acta o certificado médico de defunción',
              'Protocolo de necropsia completo con todos sus anexos',
              'Resultado del dosaje etílico en caso corresponda. (Accidentes de tránsito)',
              'Resultado de análisis toxicológico en caso corresponda. ',
              'Atestado policial ',
            ],
          },
          {
            text: 'Para casos de invalidez total y permanente:',
            items: [
              'Resultado de dosaje etílico solo en caso el asegurado era conductor del vehículo cuando ocurrió el acidente de tránsito.',
              'Ceritifcado médico que señale la invalidez total y permanente del asegurado.',
            ],
          },
        ],
      },
    ],
  },
};

const oncoRespaldo = {
  url: 'oncorespaldo',
  title: 'Onco Respaldo',
  image: 'images/logo-oncorespaldo.svg',
  subtitle: 'Salud',
  color: '#71b84a',
  description: {
    image: 'images/logo-oncorespaldo.svg',
    bgHeader: '#71b84a',
    title: '¿Qué es Onco Respaldo?',
    content: `
        Es un microseguro que brinda al cliente un respaldo económico ante el diagnóstico de cáncer, entregándole un monto en efectivo.
        <br/><br/>
        El precio de microseguro dependerá del plan que desee adquirir:
        <div class="pre">
        <table>
            <thead>
                <tr>
                <th width="20%">Plan</th>
                <th >Recibes</th>
                <th width="50%" class="right">Precio mensual</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>S/ 10,000</td>
                    <td>S/ 6 por titular y S/4 por familiar</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>S/ 20,000</td>
                    <td>S/ 10 por titular y S/7 por familiar</td>
                </tr>
            </tbody>
        </table>
        </div>
        Estos planes aplican para cualquier diagnóstico de cáncer, con la excepción del cáncer de cuello uterino en etapa inicial, en ese caso se recibiría S/3,000 en el plan 1 o de S/6,000 en el plan 2.  En caso de estar en etapa avanzada, aplican las indemnizaciones inicialmente pactadas.
        <br/><br/>
        Este microseguro tiene un periodo de carencia de 60 días; es decir, si durante los primeros 60 días después de adquirir el seguro el cliente es diagnosticado con cáncer, no podrá recibir los beneficios del seguro.
        <br/><br/>
        La vigencia del microseguro inicia desde la fecha de desembolso del crédito y estará vigente durante el plazo original del crédito.
        `,
    examples: [
      {
        title: 'Cómo funciona',
        subtitle: 'Cobertura oncológica',
        analytics: 'description/example-1',
        thumbnail: 'images/examples/or/1/card.svg',
        content: [
          {
            title:
              'Martín saca un crédito con Mibanco y decide adquirir el microseguro Onco Respaldo, con el cual estará coberturado en caso le diagnostiquen cáncer.',
            thumbnail: 'images/examples/or/1/on-comofunciona-1-1.svg',
          },
          {
            title:
              'Lamentablemente, después de 4 meses, Martín es diagnosticado con cáncer. Como cuenta con el microseguro Onco Respaldo, se acerca a MiBanco para activar el proceso de indemnización.',
            thumbnail: 'images/examples/or/1/on-comofunciona-1-2.svg',
          },
          {
            title:
              'Mibanco le envía el caso a Pacífico que, tras validarlo, activa la cobertura entregando la indemnización a Martín.',
            thumbnail: 'images/examples/or/1/on-comofunciona-1-3.svg',
          },
        ],
      },
    ],
  },
  coverages: {
    cards: [
      {
        title: '¿Qué le cubre?',
        isLarge: true,
        items: [
          {
            title: 'Diagnóstico de cáncer',
            thumbnail:
              'images/insurances/oncorespaldo/coverage/ilustracion-cancer.svg',
            description:
              'En caso el cliente sea diagnosticado de cáncer* por primera vez, recibirá un pago en efectivo, según el plan que haya elegido.',
            info: '*Revisar exclusiones para detalles.',
            details: [],
          },
        ],
      },
      {
        title: 'Beneficio adicional',
        isLarge: true,
        items: [
          {
            title: 'Médicos por teléfono',
            thumbnail:
              'images/insurances/oncorespaldo/coverage/ilustracion-medicos-por-telefono.svg',
            description:
              'Doctores especializados en medicina interna orientarán al cliente por teléfono sin costo alguno las 24 horas del día, todos los días del año.',
            details: [],
          },
        ],
      },
    ],
  },
  exclutions: {
    cards: [
      {
        title: '¿Cuándo no le cubre?',
        items: [
          {
            thumbnail:
              'images/insurances/oncorespaldo/exclutions/ilustracion-cancer-preexistencia.svg',
            title: 'Cáncer detectado antes de la contratación',
          },
          {
            description:
              'En caso de ser diagnosticado con un Melanoma Maligno el cliente seguirá coberturado de manera normal, ya que es un tipo de cáncer de piel que tiene la mayor probabilidad de crecer y propagarse, considerándose. más grave; por ello, está cubierto. Por otro lado, gran cantidad de casos de cáncer de piel no tienen riesgo de propagación por lo que no son coberturados. Esta determinación la hace un médico.',
            thumbnail:
              'images/insurances/oncorespaldo/exclutions/ilustracion-cancer-piel.svg',
            title: 'Cáncer de piel sin melanoma maligno',
          },
          {
            thumbnail:
              'images/insurances/oncorespaldo/exclutions/ilustracion-cancer-sida.svg',
            title: 'Cáncer asociado a VIH/SIDA',
          },
        ],
      },
    ],
  },
  howtouse: {
    title: 'Después del diagnóstico…',
    items: [
      {
        title: '¿Cómo activar cobertura?',
        url: '/home',
        analytics: 'how-to-use/muerte-accidental',
        items: [
          {
            title:
              'Una vez diagnosticado, el cliente se debe acercar a Mibanco para solicitar la activación del seguro.',
            thumbnail: 'images/htu/on/1/on-comofunciona-2-1.svg',
          },
          {
            title:
              'El cliente tiene que llenar el Registro de Indemnización de Seguros (RIS). En ese documento se marca la cobertura de cáncer y se le indica que debe presentar el informe anátomo patológico, entregado por la clínica/hospital/médico tratante.',
            thumbnail: 'images/htu/on/1/on-comofunciona-2-2.svg',
          },
          {
            title:
              'También deberá indicar su número de cuenta de Mibanco para el depósito. En caso que no tenga una cuenta, el cliente deberá abrir una.',
            thumbnail: 'images/htu/on/1/on-comofunciona-2-3.svg',
          },
          {
            title:
              'El informe es enviado a Pacífico donde se revisa el caso y, de ser aprobado, se realizará el abono directamente en la cuenta del cliente.',
            thumbnail: 'images/htu/on/1/on-comofunciona-2-4.svg',
          },
        ],
      },
      {
        title: 'Documentos',
        url: '/home',
        analytics: 'how-to-use/documents',
        content: [
          {
            text:
              'Registro de Indemnización de Seguros (RIS). En ese documento se marca la cobertura que se desea activar.',
            items: [],
          },
          {
            text:
              'Copia de reporte anátomo patológico. Este documento detalla información del diagnóstico. Este documento detalla información del diagnóstico y es firmado por el médico especialista.',
            items: [],
          },
        ],
      },
    ],
  },
};

const proteccionFinanciera = {
  url: 'proteccion-financiera',
  title: 'Protección Financiera',
  image: 'images/logo-pf2.svg',
  subtitle: 'Vida',
  color: '#009530',
  description: {
    image: 'images/logo-pf2.svg',
    bgHeader: '#009530',
    title: '¿Qué es Vida Protección Financiera?',
    content: `
        Es un microseguro optativo, que en caso de muerte o invalidez permanente (accidental o por enfermedad) del asegurado, se otorga a los beneficiarios hasta un beneficio máximo de S/145.000.
        <br/><br/>
        El precio del microseguro dependerá de la tasa que corresponda al plazo del crédito, multiplicando dicha tasa por el monto original del crédito.
        <br/><br/>
        La cobertura del microseguro, para fallecimiento, inicia desde la fecha de desembolso del crédito y estará vigente durante el plazo original del mismo. En el caso de la cobertura de invalidez, la vigencia inicia a los 6 meses de iniciado el crédito.
        La vigencia del microseguro inicia desde la fecha de desembolso del crédito y estará vigente durante el plazo original del crédito.
        <br/><br/>
        Se recomienda siempre especificar el o los beneficiarios, ya que de no hacerlo, el proceso de indemnización se prolonga por requerir certificados.
        `,
    examples: [
      {
        title: 'Cómo funciona',
        subtitle: 'Muerte natural/accidental',
        analytics: 'description/example-1',
        thumbnail: 'images/examples/pf/1/card.svg',
        content: [
          {
            title:
              'María saco un crédito de s/15.000 y decidió contratar el microseguro Protección Financiera, que estará vigente hasta que termine el plazo original de su préstamo.',
            thumbnail: 'images/examples/pf/1/pf-comofunciona-1-1.svg',
          },
          {
            title:
              'Lamentablemente María fallece en un accidente de tránsito. Su cónyuge, el beneficiario, se acerca a Mibanco para solicitar la indemnización.',
            thumbnail: 'images/examples/pf/1/pf-comofunciona-1-2.svg',
          },
          {
            title:
              'Mibanco envía la información a Pacífico que, tras validar el caso, activa la cobertura de muerte accidental y se le entregan los S/ 15,000 correspondientes a través de Mibanco al cónyuge de María.',
            thumbnail: 'images/examples/pf/1/pf-comofunciona-1-3.svg',
          },
        ],
      },
      {
        title: 'Cómo funciona',
        subtitle: 'Invalidez por accidente',
        analytics: 'description/example-2',
        thumbnail: 'images/examples/pf/2/card.svg',
        content: [
          {
            title:
              'Juan solicitó un crédito de S/10.000 y contrató el microseguro Protección Financiera. El cual estará vigente hasta que termine el plazo original del crédito.',
            thumbnail: 'images/examples/pf/2/pf-comofunciona-2-1.svg',
          },
          {
            title:
              'Una vez adquirido el microseguro y durante el período de cobertura, Juan sufre un accidente, por el que pierde ambas piernas, y es certificado por un médico por invalidez total y permanente. Juan se acerca a Mibanco para solicitar la indemnización.',
            thumbnail: 'images/examples/pf/2/pf-comofunciona-2-2.svg',
          },
          {
            title:
              'Juan activa la cobertura de su microseguro en Mibanco, y este envía la información a Pacífico que, tras validar el caso, activa la cobertura de invalidez total y permanente. Entregándole a Juan su indemnización de S/10.000',
            thumbnail: 'images/examples/pf/2/pf-comofunciona-2-3.svg',
          },
        ],
      },
      {
        title: 'Cómo funciona',
        subtitle: 'Invalidez por enfermedad',
        analytics: 'description/example-3',
        thumbnail: 'images/examples/pf/3/card.svg',
        content: [
          {
            title:
              'Alberto solicita un crédito de S/15.000 y adicionalmente contrata el microseguro de Protección Financiera. El cual estará vigente hasta que termine el plazo original del crédito.',
            thumbnail: 'images/examples/pf/3/pf-comofunciona-3-1.svg',
          },
          {
            title:
              'Durante el período de cobertura, Alberto sufre un derrame cerebral. Después de 6 meses tratamiento no tiene una recuperación exitosa, perdiendo así su capacidad para trabajar.',
            thumbnail: 'images/examples/pf/3/pf-comofunciona-3-2.svg',
          },
          {
            title:
              'La familia de Alberto deciden hacer efectiva su cobertura de invalidez por enfermedad y se acerca a Mibanco para solicitar la indemnización.',
            thumbnail: 'images/examples/pf/3/pf-comofunciona-3-3.svg',
          },
          {
            title:
              'Mibanco le envía la información a Pacífico que, tras validar el caso, activa la cobertura del microseguro se le entrega la indemnización de S/15,000 a los familiares de Alberto.',
            thumbnail: 'images/examples/pf/3/pf-comofunciona-3-4.svg',
          },
        ],
      },
    ],
  },
  coverages: {
    cards: [
      {
        title: '¿Qué le cubre?',
        isLarge: true,
        items: [
          {
            title: 'Muerte natural y accidental',
            thumbnail:
              'images/insurances/pf/coverage/ilustracion-invalidez.svg',
            description:
              'Muerte por causa natural o accidental, se activa a partir del día del desembolso del crédito.',
            details: [],
          },
          {
            title: 'Invalidez total y permanente',
            thumbnail: 'images/insurances/pf/coverage/ilustracion-muerte.svg',
            description:
              'Cubre la invalidez total y permanente debidamente acreditada con los siguientes diagnósticos:',
            details: [
              {
                icon: 'torito-icon-mission',
                name:
                  'Ausencia de función cerebral y/o inactividad total del cerebro.',
              },
              {
                icon: 'torito-icon-mission',
                name: 'Fractura incurable de la columna vertebral.',
              },
              {
                icon: 'torito-icon-mission',
                name: 'Pérdida total de visión de ambos ojos.',
              },
              {
                icon: 'torito-icon-mission',
                name: 'Pérdida completa de ambos brazos o ambas manos.',
              },
              {
                icon: 'torito-icon-mission',
                name:
                  'Pérdida completa de un brazo y una pierna o una mano y una pierna.',
              },
              {
                icon: 'torito-icon-mission',
                name:
                  'Pérdida completa de una mano y de un pie o de un brazo y un pie.',
              },
              {
                icon: 'torito-icon-mission',
                name:
                  'Pérdida de capacidad física o intelectual durante 6 meses.',
              },
            ],
          },
        ],
      },
    ],
    conditions: `Para adquirir este producto y sus coberturas el cliente debe tener entre 18 a 70 años.`,
  },
  exclutions: {
    cards: [
      {
        title: '¿Cuándo no le cubre?',
        items: [
          {
            thumbnail: 'images/insurances/pf/exclutions/group-9.svg',
            title: 'Enfermedad crónica o preexistente',
            description:
              'Enfermedades o dolencias preexistentes a la fecha de afiliación al seguro; o, enfermedades congénitas. ',
          },
          {
            description:
              'En caso de ser diagnosticado con un Melanoma Maligno el cliente seguirá coberturado de manera normal, ya que se trata de un caso grave. Sin embargo, gran cantidad de los casos de cáncer de piel son pequeños lunares o verrugas, en estos casos su tratamiento es solo extirpación y no suponen un riesgo grave.',
            thumbnail:
              'images/insurances/pf/exclutions/ilustracion-exclusion-muerte.svg',
            title: 'Suicidio o lesiones hechas a uno mismo',
          },
          {
            thumbnail: 'images/insurances/pf/exclutions/group-11.svg',
            title: 'Accidentes aéreos',
            description:
              'Accidente en avionetas o vuelos particulares. A excepción de viaje en vuelo comercial sobre ruta sujeta a itinerario regular.',
          },
          {
            thumbnail:
              'images/insurances/pf/exclutions/ilustracion-actos-por-otros-copy-2.svg',
            title: 'Participación en actos delictivos',
            description:
              'Muerte causada por actos violatorios de leyes o terrorismo.',
          },
          {
            thumbnail: 'images/insurances/pf/exclutions/deportes-riesgo.svg',
            title: 'Participación en deportes de riesgo',
            description:
              'Deportes que pongan en riesgo la integridad y vida del asegurado. (Submarinismo, montañismo, ala delta, paracaídismo, carreras)',
          },
          {
            thumbnail:
              'images/insurances/pf/exclutions/ilustracion-alcohol.svg',
            title: 'Accidentes bajo efectos de alcohol y/o drogas',
            description:
              'Siniestro occurido bajo efectos del alcohol o drogas y/o estupefacientes.',
          },
          {
            thumbnail: 'images/insurances/pf/exclutions/group-8.svg',
            title: 'Actividades temerarias',
            description:
              'La participación del asegurado en actos temerarios o actividades notoriamente peligrosas, que ponen en grave peligro su vida e integridad.',
          },
          {
            thumbnail:
              'images/insurances/pf/exclutions/ilustracion-radiacion-copy.svg',
            title: 'Inválidez causada por preexistencia',
            description:
              'Invalidez total y permanente causados por afecciones preexistentes o accidentes médicos (apoplejía, síncopes, infartos al miocardio, trombosis, ataques epilépticos u otros).',
          },
          {
            thumbnail:
              'images/insurances/pf/exclutions/ilustracion-muerte-intencional.svg',
            title: 'Muerte intencional al contratante',
            description:
              'Muerte causada por los beneficiarios para poder cobrar la indemnización',
          },
        ],
      },
    ],
  },
  howtouse: {
    title: 'Qué hacer en caso de…',
    items: [
      {
        title: 'Muerte natural o accidental',
        url: '/home',
        analytics: 'how-to-use/muerte-natural-o-accidental',
        items: [
          {
            title:
              'En un plazo no mayor a 7 días calendario, el beneficiario deberá acercarse a Mibanco para notificar la muerte del asegurado.',
            thumbnail: 'images/htu/pf/1/pf-caso-1-muerte-1.svg',
          },
          {
            title:
              'El asesor le entregará y explicará el Registro de Indemnización de Seguros (RIS). En ese documento se marca la cobertura que se desea activar y se le indica que documentos debe presentar.',
            thumbnail: 'images/htu/pf/1/pf-caso-1-muerte-2.svg',
          },
          {
            title:
              'Toda la documentación entregada a Mibanco es enviada a Pacífico para su evaluación después de ser validado, se envía el dinero a Mibanco para que sea entregado al beneficiario.',
            thumbnail: 'images/htu/pf/1/pf-caso-1-muerte-3.svg',
          },
        ],
      },
      {
        title: 'Invalidez accidental',
        url: '/home',
        analytics: 'how-to-use/invalidez-accidental',
        items: [
          {
            title:
              'Luego de 6 meses de ocurrido el accidente, el asegurado o los beneficiarios deben acercarse a Mibanco para notificar el estado de invalidez del asegurado.',
            thumbnail: 'images/htu/pf/2/pf-caso-2-invalidez-1.svg',
          },
          {
            title:
              'El asesor le entregará y explicará el Registro de Indemnización de Seguros (RIS). En ese documento se marca la cobertura que se desea activar y se le indica que documentos debe presentar.',
            thumbnail: 'images/htu/pf/2/pf-caso-2-invalidez-2.svg',
          },
          {
            title:
              'Toda la documentación entregada a Mibanco es enviada a Pacífico para su evaluación y después de ser validado, se envía el dinero a Mibanco para que sea entregado al beneficiario.',
            thumbnail: 'images/htu/pf/2/pf-caso-2-invalidez-3.svg',
          },
        ],
      },
      {
        title: 'Invalidez por enfermedad',
        url: '/how-to-use/invalidez-enfermedad',
        analytics: 'how-to-use/invalidez-por-enfermedad',
        items: [
          {
            title:
              'Si luego de 12 meses de ocurrido el siniestro, el asegurado no logra recuperar su capacidad para trabajar, los beneficiarios deben acercarse a Mibanco para notificar el estado de invalidez del asegurado.',
            thumbnail: 'images/htu/pf/3/pf-caso-3-invalidez-1.svg',
          },
          {
            title:
              'El asesor le entregará y explicará el Registro de Indemnización de Seguros (RIS). En ese documento se marca la cobertura que se desea activar y se le indica que documentos debe presentar.',
            thumbnail: 'images/htu/pf/3/pf-caso-3-invalidez-2.svg',
          },
          {
            title:
              'Toda la documentación entregada a Mibanco es enviada a Pacífico para su evaluación después de ser validado, se envía el dinero a Mibanco para que sea entregado al beneficiario.',
            thumbnail: 'images/htu/pf/3/pf-caso-3-invalidez-3.svg',
          },
        ],
      },

      {
        title: 'Documentos necesarios',
        url: '/home',
        analytics: 'how-to-use/documents',
        content: [
          {
            text: 'En caso de muerte natural:',
            items: [
              'Original o copia legalizada de la partida o acta de defunción.',
              'Historia clínica completa, foliada y fedateada*.',
            ],
          },
          {
            text: 'En caso de muerte accidental:',
            items: [
              'Partida o acta de defunción completa y legalizada.',
              'Protocolo de necropsia completo y legalizado.',
              'Atestado, informe policial o carpeta fiscal completo.',
              'Resultado de examen toxicológico y dosaje etílico.',
              'Historia clínica completa, foliada y fedateada*.',
            ],
          },
          {
            text: 'En caso de invalidez por enfermedad:',
            items: [
              'Certificado o dictamen médico emitido por la compañía en base a evaluaciones médicas por un organismo previsional (COMAFP o COMEC)',
              'Historia clínica completa, foliada y fedateada*.',
              'Exámenes clínicos y demás elementos auxiliares que se dispongan.',
            ],
          },
          {
            text: 'Adicionalmente, por invalidez accidental:',
            items: [
              'Atestado o informe policial completo o carpeta fiscal.',
              'Resultado de dosaje etílico y  examen toxicológico.',
            ],
          },
        ],
      },
    ],
  },
};

export default [
  proteccionNegocio,
  proteccionFamilia,
  oncoRespaldo,
  proteccionFinanciera,
];
